<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>
    <p class="container p-5" v-html="$t('at_work')" v-if="id == 5"></p>
    <p class="container p-5" v-html="$t('at_home')" v-if="id == 2"></p>
    <p class="container p-5" v-html="$t('at_hotal')" v-if="id == 4"></p>
    <section
      class="new-con container text-center"
      style="padding-top: 36px;"
      v-if="id == 5 || id == 2 || id == 4"
    >
      <p class="text-center">
        <router-link
          :to="$getLink() + 'purchasing'"
          class="btn btn-primary"
          style="height: 60px;font-size: 25px;"
          >{{ $t("Appointment") }}</router-link
        >
      </p>
      <p class="text-center">
        <router-link
          :to="$getLink() + 'gift/Massage_Carte'"
          class="btn btn-danger"
          style="height: 60px; font-size: 25px;"
          >{{ $t("Gift Voucher") }}</router-link
        >
      </p>
      <!-- <div class="row">
        <div class="col-md-4 col-xs-12 new-con new-con1">
          <h2>EGYPTA <span>MASSAGE</span></h2>
          <p>{{ $t('wep') }}</p>
        </div>
        <div class="col-md-4 col-xs-12 new-con new-con1">
          <h4>{{ $t('Address') }}</h4>
          <p>76 avenue du Luxembourg 4940 BASCHARAGE</p>
        </div>
        <div class="col-md-4 col-xs-12 new-con new-con3">
          <h4>{{ $t('CONTACT INFO') }}:</h4>
          <p>
            <a href="tel://+352671107278" class="contact-icon"
              >{{ $t('Phone') }}: +352671107278</a
            >
          
          
            <br />
            <a href="mailto:egypta.massage@gmail.com" class="contact-icon"
              >{{ $t('Email') }}: egypta.massage@gmail.com</a
            >
          </p>
        </div>
      </div> -->
    </section>
    <products
      style="padding-top: 36px;"
      v-if="massage.length > 0 && id != 5 && id != 2 && id != 4 && id != 3"
      :list2="massage"
      :service="id"
    />

    <!-- 3 for-caubles -->
    <div v-if="id == 3">
      <!--SECTION START-->
      <section style="background-color: white;padding-top: 20px;">
        <div class="container ">
          <div class="mb-4">
            <img
              style="max-height: 400px;width: 100%;"
              :src="baseUploadURL + image3"
              alt=""
            />
          </div>
          <div class="mb-4 mt-4">
            <div>
              <br />
              <h3>{{ $t("Massage Details") }}:</h3>
              <p>
                {{ $getByLang(body3) }}
              </p>
            </div>
            <div>
              <h3>
                {{ $t("Price") }}:
                <span style="color:red">
                  {{ $t("per person") }}
                </span>
              </h3>
              <div style="padding-left: 20px">
                <ul>
                  <li v-for="itm of prices3" :key="itm.id">
                    {{ itm.minutes }} minutes = {{ itm.price }}€
                    {{
                      itm.recommended == 1 ? "(" + $t("recommended") + ")" : ""
                    }}
                    <p style="font-size: 10px;color: #666;line-height: 15px;">
                      {{ itm.services.map((el) => $getByLang(el.name)) }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p class="text-center">
        <!-- <router-link
          :to="$getLink() + 'booking/' + slug3"
          class="btn btn-primary"
          style="height: 60px;font-size: 25px;"
          >{{ $t('Appointment') }}</router-link
        > -->
        <a
          href="https://salonkee.lu/salon/egypta-massage-bascharage?lang=fr"
          target="_blank"
          class="btn btn-primary"
          style="height: 60px; font-size: 25px"
        >
          {{ $t("Appointment") }}
        </a>
      </p>
      <p class="text-center">
        <router-link
          :to="$getLink() + 'gift/' + slug3"
          class="btn btn-danger"
          style="height: 60px; font-size: 25px;"
          >{{ $t("Gift Voucher") }}</router-link
        >
      </p>
      <!--SECTION END-->
    </div>
  </div>
</template>

<script>
import products from "./home/products";

export default {
  components: {
    products,
  },
  data() {
    return {
      name: null,
      id: null,

      massage: [],
      baseUploadURL: this.$baseUploadURL,
      name3: null,
      body3: null,
      image3: null,
      id3: null,
      slug3: null,
      prices3: null,
    };
  },

  created() {
    const slug = this.$route.params.slug;

    if (slug) {
      this.$http.get(`services/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.name;
            this.massage = res.data.massage;
            this.id = res.data.id;

            if (this.id == 3) {
              this.$http.get(`massage/getById/COUPLES_MASSAGE_ISIS`).then(
                (res2) => {
                  if (res2.data) {
                    this.name3 = res2.data.name;
                    this.body3 = res2.data.body;
                    this.image3 = res2.data.image;
                    this.id3 = res2.data.id;
                    this.slug3 = res2.data.slug;
                    this.prices3 = res2.data.prices;
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  watch: {
    "$route.params.slug"(newv) {
      // if (old != null) {
      this.$http.get(`services/getById/${newv}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.name;
            this.massage = res.data.massage;
            this.id = res.data.id;
          }
        },
        (err) => {
          console.log(err);
        }
      );
      // }
    },
  },
};
</script>

<style>
.new-con h2 {
  color: #f26838;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con h2 span {
  color: #13293d;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con p a {
  color: #8b4513;
}
.new-con h4 {
  text-transform: uppercase;
  padding-bottom: 12px;
  color: #13293d;
  font-weight: 700;
}
</style>
